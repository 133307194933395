.home-container {
  font-family: "Roboto", sans-serif;
  margin: 0 auto;
  width: 100%;
  border-bottom: 2px solid #964ec2;
}

.Main {
  padding: 0 40px;
  margin: 40px 0;
  font-family: "Roboto", sans-serif;
}

.Loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dd6ca7;
}

.loading__screen {
  color: #dd6ca7;
  font-family: "Roboto";
  font-size: 20px;
}
/* Carousel config */
.slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
}

.slick-next {
  position: absolute;
  top: 95%;
  right: 48%;
}

.slick-prev {
  position: absolute;
  z-index: 1;
  top: 95%;
  left: 48%;
}
/* ==== Ảnh ==== */

.hero-movie {
  width: 100%;
  height: 600px;
  position: relative;
}

.movie__thumb {
  width: 100%;
  height: 100%;
}

.thumbs {
  filter: brightness(60%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ===== Thông tin ===== */
.movie-info {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  padding: 20px 30px;
  color: whitesmoke;
  height: 100%;
  width: 450px;
}

.poster {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.poster-img {
  width: 100px;
  border: 1px solid #313866;
  border-radius: 7px;
}

.sub-info {
  margin: 20px 0;
}
.sub-info ul {
  display: flex;
  font-size: 14px;
}

.sub-info li:first-child {
  list-style: none;
}

.sub-info li {
  padding: 0 10px;
  list-style: disc;
  list-style-position: inside;
}

.movie-title {
  margin-bottom: 20px;
  color: white;
  font-weight: 600;
}

.movie-des {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6; /* Số dòng bạn muốn hiển thị */
}

.episodes {
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.episodes span {
  color: #dd6ca7;
}

.all-eps {
  border-right: 1px solid #ff7bbf;
  padding-right: 10px;
}

.status {
  padding-left: 10px;
}

.home-action__btn {
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  align-items: center;
  margin-top: 20px;
}

.home__play-btn {
  padding: 8px;
  border-radius: 7px;
  border: #ff7bbf solid 1px;
  background-color: #313866;
  color: #ff7bbf;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.home__play-btn:hover {
  background-color: #1b1f38;
}

.playlist__btn {
  padding: 8px;
  border-radius: 7px;
  border: #ff7bbf solid 1px;
  background-color: #313866;
  color: #ff7bbf;
  font-size: 16px;
  font-family: "Roboto";
  cursor: pointer;
}

.playlist__btn:hover {
  background-color: #1b1f38;
}

.playlist__btn:active {
  background-color: #3c436c;
}

/* ======= News Movie =======*/
.news-box {
  width: 100%;
  /* display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  gap: 8px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-auto-flow: row;
  grid-gap: 10px;
}

.box-title {
  color: #dd6ca7;
  padding: 10px 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.news-movie {
  border: 2px solid #2d3352;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 2px 10px 24px -6px rgba(90, 40, 160, 0.44);
  -webkit-box-shadow: 2px 10px 24px -6px rgba(90, 40, 160, 0.44);
  -moz-box-shadow: 2px 10px 24px -6px rgba(90, 40, 160, 0.44);
}

.news__movie--thumb {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.news-movie img {
  width: 100%;
  height: 100%;
  filter: brightness(85%);
  transition: 300ms all ease;
}

.news-movie img:hover {
  transform: scale(110%);
  filter: brightness(60%);
}

.news__movie--info {
  height: 100%;
  background-color: #1b1f38;
  color: #ff7bbf;
}

.news__movie--sub-info ul {
  display: flex;
  align-items: center;
  list-style: disc;
  gap: 20px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
  padding: 8px 8px 20px 8px;
  color: #dd6ca6c3;
}

.news__movie--name {
  padding: 10px 8px 0 8px;
  display: -webkit-box; /* Sử dụng -webkit-box để hỗ trợ WebKit */
  -webkit-box-orient: vertical; /* Hiển thị text theo chiều dọc */
  overflow: hidden;
  /* Số dòng tối đa */
  -webkit-line-clamp: 1; /* Giới hạn số dòng là 2 */
}
