.header {
  width: 100%;
  padding: 20px 100px;
  background-color: #212226;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px #964ec2 solid;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  top: 0;
  z-index: 999;
  position: sticky;
}
.header__right {
  display: flex;
  align-items: center;
}

.header__logo {
  width: 45px;
  transform: scaleX(-1);
  margin-right: 100px;
}

/* ===== Navbar ==== */
.nav {
  display: flex;
  align-items: center;
}
.nav_a {
  text-decoration: none;
  color: #964ec2;
  padding: 8px 14px;
  cursor: pointer;
}

.nav_a:hover {
  color: #ff7bbf;
}

.header__type--box {
  position: relative;
}

.header__cataloge {
  width: 300px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  grid-auto-flow: row;
  border-radius: 7px;
  background-color: #964ec2;
  position: absolute;
  top: 150%;
  transition: 400ms all ease;
}

.cataloge__item {
  color: white;
  padding: 14px;
  cursor: pointer;
}

.cataloge__item:hover {
  background-color: #6e398f;
  border-radius: 7px;
}
/* ====== Search ====== */
.search-icon {
  color: #964ec2;
  cursor: pointer;
  padding: 8px 10px;
}
.search-icon:hover {
  color: #ff7bbf;
}

.search input {
  width: 300px;
  height: 29px;
  border: none;
  border-radius: 7px;
  padding: 5px 5px;
  transition: 0.4s ease;
}

.search {
  position: relative;
}

.pre__search {
  width: 300px;
  background-color: #6e398f;
  position: absolute;
  border: double 2px #964ec2;
  border-radius: 8px;
  overflow: auto;
  transition: 0.4s all ease;
}

.pre__box {
  width: 100%;
  height: 100px;
  background-color: #212226;
  border-bottom: 2px solid gray;
  border-radius: 8px;
  display: flex;
}

.pre__info--box {
  padding: 10px;
}

.pre__img {
  height: 100%;
  width: 70px;
}

.pre__name {
  color: #ff7bbf;
  margin-bottom: 5px;
}

.pre__origin-name {
  color: #964ec2;
  font-size: small;
}
.page__button {
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
}

.page__button button {
  padding: 2px 10px;
  border: none;
  border-radius: 3px;
  background-color: #964ec2;
  cursor: pointer;
}

.page__button button:hover {
  background-color: #7d3fa3;
}

.page__button button:active {
  background-color: #964ec2;
}
