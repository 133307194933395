.playlist__box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-auto-flow: row;
  grid-gap: 10px;
  padding: 20px 40px;
  font-family: "Roboto";
}

.playlist__item {
  position: relative;
  cursor: pointer;
  border-radius: 7px;
  overflow: hidden;
  border: 2px solid #2d3352;
  box-shadow: 2px 10px 24px -6px rgba(90, 40, 160, 0.44);
  -webkit-box-shadow: 2px 10px 24px -6px rgba(90, 40, 160, 0.44);
  -moz-box-shadow: 2px 10px 24px -6px rgba(90, 40, 160, 0.44);
}

.playlist__item img {
  width: 100%;
  height: 100%;
  transition: 400ms all ease;
}

.playlist__item img:hover {
  transform: scale(110%);
  filter: brightness(60%);
}

.playlist__item h1 {
  color: white;
  padding: 5px;
}

.remove__playlist {
  color: pink;
  position: absolute;
  background-color: #313866;
  padding: 6px;
  border-radius: 0 0 7px 7px;
  top: 0;
  right: 10px;
  transition: 400ms all ease;
}

.playlist__info {
  position: absolute;
  width: 100%;
  background-color: #313866;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
  justify-content: space-around;
  transition: 400ms all ease;
}
